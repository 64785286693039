<template>
  <div>
   
    <ProformaData/>

  </div>
</template>

<script>

import ProformaData from '@/components/ProformaData.vue';

export default {
  name: "PROFORMA",
  components: {
    ProformaData,
  }
};
</script>