<template>
  <div>
    <v-container>
      <h1 class="text-center mt-4 greencolor">OTZ UNIT</h1>

      <v-row justify="center" class=" pa-5">
        <v-card
          class="pa-8 rounded-xl"
          elevation="12"
          width="400"
          max-height="425"
        >

          <v-card color="green">
            <h2 class="white--text ml-3 text-center">{{ totalPatients }}<span class="d-block">Patients</span></h2>
          </v-card>
          <v-img src="proforma.jpg"
            contain 
            class="my-7 rounded-xl"
          ></v-img>
          
          <v-row justify="space-around">
            <v-btn
              rounded
              :to="{ name: 'otzpatients' }"
              color="green darken-2"
              class="white--text"
            >
              <v-icon>mdi-account-multiple</v-icon>
              Patients
            </v-btn>
            <v-btn
              rounded
              to='/otz-newpatient'
              color="green darken-2"
              class="white--text"
            >
              <v-icon>mdi-plus-box</v-icon>
              New Patient
            </v-btn>
          </v-row>
        </v-card>
      </v-row>
    </v-container>
  </div>
</template>

<script>


export default {
  name: 'ProformaData',
  data () {
    return {
      totalPatients: 0,
    }
  },
 
  }
</script>

<style>
  .greencolor {
    color: #0b580b;
  }
</style>